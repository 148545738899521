import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Space, Table } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { PiDownload, PiUpload } from 'react-icons/pi';
import { Filter } from 'iconsax-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageDictionaryCreate from '../../../../urls/urlPageDictionaryCreate';
import urlPageDictionariesImport from '../../../../urls/urlPageDictionariesImport';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import { getDictionariesTableColumns } from './PageDictionaries.const';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import basePagination from '../../../../const/pagination';
import useToggle from '../../../../app/hooks/useToggle';
import applyFilter from '../../../../lib/applyFilter';
import resetFilter from '../../../../lib/resetFilter';
import useDownload from '../../../../app/hooks/useDownload';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import getQueryParams from '../../../../lib/getQueryParams';
import handleResponse from '../../../../lib/handleResponse';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import getFeatureSourceTranslation from '../../../Featured/utils/getFeatureSourceTranslation';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import FormFilterDictionary from '../../forms/FormFilterDictionary';
import Sidebar from '../../../../components/sidebar/Sidebar';
import ActionButton from '../../../../layout/components/action-button';
import PreviewDictionaryTranslation from '../../components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import FormGenerateTemplate from '../../forms/FormGenerateTemplate';
import {
  useDeleteDictionaryMutation,
  useGetDictionariesFilterQuery,
  useGetDictionariesQuery,
  useUpdateDictionaryMutation,
} from '../../api/dictionariesApiSlice';



const PageDictionaries = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ filterOpen, toggleFilterSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ openModal, toggleModal ] = useToggle();
  const [ openExportTemplateModal, toggleExportTemplateModal ] = useToggle();
  const [ previewDictionary, setPreviewDictionary ] = useState({});
  const [ initFilterValues, setInitFilterValues ] = useState({});

  const navigationSource = searchQueryParams.get('source') ?? null;
  const searchFilterFields = [ 'name', 'source', 'field', 'field_value', 'version', 'created_from', 'created_to' ];

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFilterFields, isFilter: true });

  const { download: downloadTemplate, isLoading: isDownloadingTemplate } = useDownload({ method: 'POST' });

  const { data = { data: [], pagination: {} }, isLoading } = useGetDictionariesQuery({
    queryParams: `${searchParams.toString()}&searchJoin=and&include=structure,translations`,
  });

  const { data: filterData = { fields: [], sources: [], versions: [] } } = useGetDictionariesFilterQuery({
    queryParams: navigationSource ? `source=${navigationSource}` : '',
  });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  const [ deleteDictionary, { isLoading: isLoadingDeleteDictionary } ] = useDeleteDictionaryMutation();
  const [ updateDictionary, { isLoading: isLoadingUpdateDictionary } ] = useUpdateDictionaryMutation();

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (key === 'created_to') {
          initValues[key] = value.substring(0, 10);
        } else {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleDelete = (id) => {
    deleteDictionary(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleView = (dictionary) => {
    setPreviewDictionary(dictionary);
    toggleModal();
  };

  const handleChangeStatus = (id, values) => {
    updateDictionary({ dictionaryId: id, values });
  };

  const { download } = useDownload();

  const onDownload = async (dictionary_id) => {
    download(`dictionaries/${dictionary_id}/exports`);
  };

  const onDownloadTemplate = (values) => {
    downloadTemplate('dictionaries/templates/exports', values);
    toggleExportTemplateModal();
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openModal}
        onCancel={toggleModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <PreviewDictionaryTranslation
          dictionaryDetails={previewDictionary}
          languages={languages?.languages}
        />
      </Modal>

      <Modal
        title={<IntlMessages id='dictionaries-export-template' />}
        width={700}
        centered
        destroyOnClose
        visible={openExportTemplateModal}
        onCancel={toggleExportTemplateModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormGenerateTemplate
          onSubmit={onDownloadTemplate}
          isSubmitting={isDownloadingTemplate}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbActive={<IntlMessages id='dictionaries-breadcrumbs' />}
        />

        <Col>
          <Space>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.IMPORT ]}>
              <ActionButton
                title={<IntlMessages id="ui-general-import" />}
                icon={<PiUpload className="btn-icon-mr-1" />}
                onClick={() => navigate(urlPageDictionariesImport({ source: navigationSource ?? null }))}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.EXPORT_TEMPLATE ]}>
              <ActionButton
                title={<IntlMessages id="dictionaries-export-template" />}
                icon={<PiDownload className="btn-icon-mr-1" />}
                onClick={toggleExportTemplateModal}
              />
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.CREATE ]}>
              <ActionButton
                title={<IntlMessages id="ui-general-create" />}
                icon={<RiAddLine className="btn-icon-mr-1" />}
                onClick={() => navigate(urlPageDictionaryCreate({ source: navigationSource ?? null }))}
              />
            </PrivateComponent>
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          title={<IntlMessages id='dictionaries-filter-title' />}
          visible={filterOpen}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFilterDictionary
            isSubmitting={false}
            initialValues={initFilterValues}
            filterOptions={filterData}
            showSourceFilter={!navigationSource}
            onReset={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                navigationSource,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading || isLoadingDeleteDictionary || isLoadingUpdateDictionary)}
              rowKey="id"
              columns={getDictionariesTableColumns(handleDelete, handleView, handleChangeStatus, onDownload, navigationSource)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDictionaries;
