import React from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { RiArrowLeftLine } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageAchievements from '../../../../urls/urlPageAchievements';
import urlPageAchievementsEdit from '../../../../urls/urlPageAchievementsEdit';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import Spinner from '../../../../components/Spinner';
import ContentCard from '../../components/ContentCard';
import ActionButton from '../../../../layout/components/action-button';
import EmptyDataPlaceholder from '../../../../components/EmptyDataPlaceholder';
import AchievementDetailsCard from '../../components/AchievementDetailsCard';
import { useGetAchievementQuery } from '../../api/achievementsApiSlice';



const PageAchievementDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: { data: achievement } = { data: {} },
    isFetching: isAchievementFetching,
  } = useGetAchievementQuery(id);

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageAchievements()}>
              <IntlMessages id='achievements-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={achievement?.name}
        />
        <Col>
          <ActionButton
            icon={<EditOutlined className="hp-mr-4" />}
            onClick={() => {
              navigate(urlPageAchievementsEdit({ id }));
            }}
            title={<IntlMessages id='ui-general-edit' />}
          />
          <ActionButton
            icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
            onClick={() => {
              navigate(urlPageAchievements());
            }}
            title={<IntlMessages id='ui-general-back-to-list' />}
          />
        </Col>
      </Row>

      <Spinner spinning={isAchievementFetching}>
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <AchievementDetailsCard data={achievement} />
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]}>
          <Col xs={24} lg={24}>
            <Card title={<IntlMessages id="achievements-attached-images" />}>
              <Row gutter={[ 16, 32 ]}>
                {achievement?.images?.data?.length > 0 ?
                  achievement?.images?.data?.map((item) => {
                    return (
                      <Col key={item.id} className="gutter-row" xs={24} sm={8} lg={6} xxl={4}>
                        <ContentCard
                          data={item}
                          onClick={() => {}}
                        />
                      </Col>
                    );
                  }) : (
                    <EmptyDataPlaceholder placeholder={<IntlMessages id="achievements-no-attached-images" />} />
                  )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageAchievementDetails;
