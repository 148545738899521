import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Alert, Col, Collapse, DatePicker, Divider, Row, Tooltip, Typography } from 'antd';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { mkValidationSchema, POPUP_TEMPLATE_CONTENT_TYPE } from './FormCreatePopup.const';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Constructors/const/templates';
import { fieldTypeToValidationRule } from '../../../Content/forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import { countries } from '../../../../const/countries';
import { targetPlatformOptions } from '../../../../const/system';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { POPUPS_SOURCE } from '../../pages/PagePopups/PagePopups.const';
import CountrySelector from '../../../../components/CountrySelector';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makeSelectFilterSort from '../../../../lib/makeSelectFilterSort';
import modifyDefaultValue from '../../../Constructors/utils/modifyDefaultValue';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import PresetPreview from '../../../../components/PresetPreview';
import mkMainAndOptionalTemplateFields from '../../../Constructors/utils/mkMainAndOptionalTemplateFields';
import applyPreset from '../../../../lib/applyPreset';
import Spinner from '../../../../components/Spinner';
import isDateDisabled from '../../../../lib/isDateDisabled';
import getFullFieldName from '../../../Content/utils/getFullFieldName';
import isFieldRequired from '../../../Content/utils/isFieldRequired';
import disableDateBeforeYesterday from '../../../../lib/disableDateBeforeYesterday';
import mkTemplateDynamicFormData from '../../../Content/utils/mkTemplateDynamicFormData';
import DynamicFormDataFields from '../../../Content/components/DynamicFormDataFields';
import getConfigurations from '../../../../lib/getConfigurations';
import { useGetTemplatesQuery } from '../../../Constructors/api/templatesApiSlice';
import { useGetPresetsQuery } from '../../../Presets/api/presetsApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

let validation = {};

const FormCreatePopup = ({
  initialErrors,
  onSubmit,
  isSubmitting,
}) => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { onBlur } = useDatepickerHandler();
  const [ currentTemplate, setCurrentTemplate ] = useState(null);

  const popupsConfig = getConfigurations(POPUPS_SOURCE);

  if (!isEmpty(initialErrors)) {
    window.scrollTo(0, 0);
  }

  const defaultConfigUtcValue = popupsConfig?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !popupsConfig?.publish?.show_utc0_field;
  const useDatePickerTime = popupsConfig?.publish?.use_time ?? false;
  const hasReleaseRange = popupsConfig?.release_range ?? false;

  const { data: templates = { data: [] }, isFetching: isTemplatesFetching } = useGetTemplatesQuery({
    queryParams: `limit=0&search=status:1;content_type:${POPUP_TEMPLATE_CONTENT_TYPE}`,
  });

  const {
    data: { data: presets } = { data: [] },
    isFetching: isPresetsFetching,
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${currentTemplate}`,
  }, { skip: !currentTemplate });

  const template = templates?.data?.find((template) => Number(template.id) === Number(currentTemplate));
  const modifiedErrors = useModifiedDynamicDataErrors(currentTemplate, template, initialErrors, language);
  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(template?.fields?.data);

  useEffect(() => {
    validation = mkValidationSchema(popupsConfig, {});
  }, [ isPresetsFetching ]);

  const applyTemplate = async (value, setFieldValue) => {
    setCurrentTemplate(value);
    await setFieldValue('preset_id', null);
    await setFieldValue('template_id', value);

    if (!value) {
      setFieldValue('data', {});
      return;
    }

    const data = {};
    const dataSchema = {};
    const template = templates?.data?.find((template) => template.id === value);

    template?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach((field) => {
        const fieldName = getFullFieldName(field);

        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[fieldName] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        data[fieldName] = {
          value: field.type === TYPE_IMAGE ? [] : modifyDefaultValue(field),
          template_field_id: field.id,
        };
      });

    setFieldValue('data', data);
    setFieldValue('target', {
      min_version: template?.target?.data?.min_version,
      max_version: template?.target?.data?.max_version,
      platform: template?.target?.data?.platform,
      countries: template?.target?.data?.countries,
    });

    validation = mkValidationSchema(popupsConfig, {
      data: Yup.object().shape(dataSchema),
    });
  };

  const initialValues = {
    use_utc_zero_time: defaultConfigUtcValue,
    is_published: false,
    released_at: null,
    ...hasReleaseRange && {
      released_to: null,
    },
    template_id: null,
    preset_id: null,
    target: {
      min_version: null,
      max_version: null,
      platform: [],
      countries: [],
    },
    data: {},
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialErrors={modifiedErrors}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, template?.fields?.data), resetForm);
      }}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, resetForm }) => {
        const preset = presets?.find((preset) => Number(preset.id) === Number(values.preset_id));

        return (
          <Form layout="vertical" >
            <Spinner spinning={isSubmitting}>
              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: 'popups-breadcrumbs' })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        name='title'
                        required
                        label={<IntlMessages id="ui-general-title" />}
                      >
                        <Input
                          name="title"
                          placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
                        />
                      </FormItem>
                    </Col>

                    {hasReleaseRange ? (
                      <>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-at' })}
                            name='released_at'
                            required={values?.is_published}
                          >
                            <DatePicker
                              name='released_at'
                              showToday
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                [],
                                !isEmpty(values?.released_to)
                                && date.isAfter(moment(values?.released_to)),
                              )}
                              value={values?.released_at ? moment(values.released_at) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_at', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_at', true);
                                await onBlur('released_at', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-to' })}
                            name='released_to'
                          >
                            <DatePicker
                              name='released_to'
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                [],
                                !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                              )}
                              value={values?.released_to ? moment(values.released_to) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_to', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_to', true);
                                await onBlur('released_to', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <FormItem
                          label={intl.formatMessage({ id: 'ui-general-released-at' })}
                          name='released_at'
                          required={values?.is_published}
                        >
                          <DatePicker
                            name='released_at'
                            showToday
                            showTime={useDatePickerTime
                              ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                              : false}
                            disabledDate={disableDateBeforeYesterday}
                            value={values?.released_at ? moment(values.released_at) : null}
                            onChange={async (date, dateString) => {
                              await setFieldValue('released_at', dateString);
                            }}
                            onBlur={async ({ target }) => {
                              await setFieldTouched('released_at', true);
                              await onBlur('released_at', target.value, setFieldValue);
                            }}
                          />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={isTemplatesFetching}
                          allowClear
                          showSearch
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          options={getTemplateOptions(templates.data)}
                          onChange={async (value) => {
                            await applyTemplate(value, setFieldValue);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                          filterSort={(optionA, optionB) => makeSelectFilterSort(optionA.label, optionB.label)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={isPresetsFetching}
                          allowClear
                          showSearch
                          disabled={!currentTemplate}
                          name="preset_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                          options={getTemplateOptions(presets)}
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, template?.fields?.data ?? []);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                          filterSort={(optionA, optionB) => makeSelectFilterSort(optionA.label, optionB.label)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        name='use_utc_zero_time'
                        label={
                          <Row className='w-full' justify="space-between" align="middle">
                            <IntlMessages id="features-use-utc" />
                            <Tooltip
                              placement="top"
                              title={
                                <>
                                  <IntlMessages id="features-use-utc-info" />
                                  <div>
                                    <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                      <u><IntlMessages id="ui-general-documentation-link" /></u>
                                    </Link>
                                  </div>
                                </>
                              }
                            >
                              <InfoCircleOutlined className='hp-ml-8' />
                            </Tooltip>
                          </Row>
                        }
                      >
                        <Switch
                          name="use_utc_zero_time"
                          defaultChecked={defaultConfigUtcValue}
                          disabled={isDisabledUtcField}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              {values?.template_id && (
                <>
                  <Divider />
                  <Title className="hp-mb-24" level={4}>
                    <IntlMessages id="ui-general-template-data" />
                  </Title>
                  <Row gutter={[ 16, 16 ]} className="hp-mb-24">
                    <Col span={24}>
                      <DynamicFormDataFields
                        setFieldValue={setFieldValue}
                        fields={mainFields}
                        values={values}
                        span={24}
                      />
                      {!!optionalFields.length && (
                        <Collapse>
                          <Panel
                            key="1"
                            header={
                              <Title level={5} className='collapse-item-title'>
                                <IntlMessages id="ui-general-additional-parameters" />
                              </Title>
                            }
                          >
                            <DynamicFormDataFields
                              setFieldValue={setFieldValue}
                              fields={optionalFields}
                              values={values}
                              span={24}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Col>
                  </Row>

                  <Divider />
                  <Title className="hp-mb-24" level={4}><IntlMessages id="features-form-collapse-title-targets" /></Title>

                  <Row gutter={[ 16, 16 ]}>
                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-min-version' })}
                        name='target.min_version'
                      >
                        <Input
                          name="target.min_version"
                          placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-min-version-placeholder' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-max-version' })}
                        name='target.max_version'
                      >
                        <Input
                          name="target.max_version"
                          placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-max-version-placeholder' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-platform' })}
                        name='target.platform'
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          name="target.platform"
                          options={targetPlatformOptions}
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-platform' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-countries' })}
                        name='target.countries'
                        required
                      >
                        <CountrySelector
                          onSelectAll={async () => {
                            await setFieldValue(
                              'target.countries',
                              countries.map((country) => country.value).sort(),
                            );
                          }}
                          onSelectAllDisabled={values?.target?.countries?.length === countries.length}
                          setFieldValue={setFieldValue}
                          name='target.countries'
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-countries' })}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </>
              )}

              <Alert
                message={<IntlMessages id="features-edit-has-changes" />}
                description={
                  <Row justify='end' className='hp-mt-32'>
                    <Col>
                      <Row>
                        <IntlMessages id="features-edit-publish-to-hc" />
                        <Switch name="is_published" className='hp-ml-16' />
                      </Row>
                    </Col>
                  </Row>
                }
                type="info"
                showIcon
              />

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      setCurrentTemplate(null);
                      resetForm(initialValues);
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreatePopup.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormCreatePopup;
