import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Descriptions, Tabs } from 'antd';
import isNull from 'lodash/isNull';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { LANGUAGE_STATUS } from '../../../Language/pages/PageLanguages/PageLanguages.const';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';
import { useGetDictionaryQuery } from '../../api/dictionariesApiSlice';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import Spinner from '../../../../components/Spinner';



const { TabPane } = Tabs;


const LanguageTranslation = ({ languages, dictionary }) => {
  return (
    <div className="hp-mb-24">
      <Tabs tabPosition='left'>
        {languages.map((langItem) => (
          <TabPane tab={langItem?.name || EMPTY_VALUE_PLACEHOLDER} key={langItem.id}>
            {dictionary?.structure?.data?.fields?.data.map((field) => {
              const translation = dictionary.translations.data.find((item) => {
                return item.structure_field_id === field.id && langItem.id === item.language_id && langItem.status === LANGUAGE_STATUS.enabled;
              });

              return (
                <div key={langItem.name} className="collapse-padding-reset">
                  <Descriptions layout="horizontal" column={1}>
                    <Descriptions.Item
                      key={field.label}
                      label={<span className='ant-descriptions-item-fixed-label'>{`${field.label}:`}</span>}
                    >
                      {translation?.text || EMPTY_VALUE_PLACEHOLDER}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              );
            })}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

LanguageTranslation.propTypes = {
  languages: PropTypes.array.isRequired,
  dictionary: PropTypes.object.isRequired,
};

const PreviewDictionaryTranslation = ({ dictionaryDetails, dictionaryId = null, languages, allowEdit = true }) => {
  const navigate = useNavigate();
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const { data: dictionary = {}, isLoading: isLoadingDictionary = false } = useGetDictionaryQuery({ id: dictionaryId, include: 'structure' }, { skip: !dictionaryId });

  const data = isNull(dictionaryId) ? dictionaryDetails : dictionary;
  const langList = [];


  if (data?.structure?.data?.language_ids?.length) {
    data.structure.data.language_ids.forEach((langId) => {
      const lang = languages.find((lang) => lang.id === langId);

      langList.push(lang);
    });
  } else {
    return langList;
  }

  return (
    <Spinner spinning={isLoadingDictionary}>
      <div>
        {!isLoadingDictionary && (
          <div>
            {!data?.translations?.data.length ? (
              <div className="hp-d-flex hp-align-items-center">
                <IntlMessages id='dictionaries-missed-translations' />
                {dictionaryId && (
                  <Link className="hp-ml-8" to={urlPageTranslationDetails({ id: dictionaryId, source: navigationSource })}>
                    <IntlMessages id='translations-edit-button' />
                  </Link>
                )}
              </div>
            ) : (
              <LanguageTranslation
                key={1}
                languages={langList}
                dictionary={data}
              />
            )}
          </div>
        )}
      </div>

      {!isLoadingDictionary && allowEdit && (
        <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
          <div className='hp-mt-18 centered-item'>
            <Button onClick={() => navigate(urlPageTranslationDetails({ id: data.id, source: navigationSource }))}>
              <IntlMessages id="ui-general-edit" />
            </Button>
          </div>
        </PrivateComponent>
      )}
    </Spinner>
  );
};

PreviewDictionaryTranslation.propTypes = {
  dictionaryId: PropTypes.any,
  dictionaryDetails: PropTypes.object,
  languages: PropTypes.array.isRequired,
  allowEdit: PropTypes.bool,
};

export default PreviewDictionaryTranslation;
