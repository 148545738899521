import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { PiInfinity, PiPencil } from 'react-icons/pi';
import { IoEarth } from 'react-icons/io5';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RiErrorWarningLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPagePopupEdit from '../../../../urls/urlPagePopupEdit';
import { HasRights, PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { getDictionaryIdFromDynamicData } from '../../../Featured/pages/PageFeatures/PageFeatures.const';
import PublishedTag from '../../../../components/PublishedTag';
import CollapsibleText from '../../../../components/CollapsibleText';
import {
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';



dayjs.extend(isSameOrBefore);
dayjs.extend(utc);


export const POPUPS_SOURCE = 'popups';

export const getPopupsTableColumns = (
  handlePreviewDictionary,
  handleViewDetails,
  handleDelete,
  handleClickImage,
  popupsConfig,
  tableSize,
) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.06,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-published" />,
    align: 'center',
    dataIndex: 'is_published',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value, item) => <PublishedTag published={Boolean(value)} publishedDate={item.published_at} />,
  },
  {
    title: <IntlMessages id="ui-general-preview" />,
    dataIndex: 'preview',
    align: 'center',
    width: tableSize.width * 0.14,
    render: (value, item) => {
      const timestamp = Date.now();

      return (
        <LazyLoadImage
          onClick={() => {
            if (!isEmpty(item?.preview)) {
              handleClickImage(item.preview);
            }
          }}
          style={!isEmpty(item?.preview) ? { cursor: 'pointer' } : {}}
          effect='blur'
          className='hp-cursor-pointer'
          alt="popups preview"
          src={value ? `${value}?time=${timestamp}` : EMPTY_IMAGE_PLACEHOLDER}
        />
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    width: tableSize.width * 0.12,
    align: 'center',
    render: (item, value) => {
      const rows = item.split('\n');

      return (
        rows.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <CollapsibleText text={content} />
            &nbsp;
            {index === 0 && value?.is_published && dayjs(value.updated_at).isAfter(dayjs(value.published_at)) && (
              <Tooltip placement="right" title={<IntlMessages id="ui-general-unpublished-tooltip-title" />}>
                &nbsp;
                <ExclamationCircleOutlined className="hp-text-color-warning-1" />
              </Tooltip>
            )}
          </div>
        ))
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-template" />,
    width: tableSize.width * 0.12,
    align: 'center',
    render: (item) => <div>{item?.template?.data?.title ?? EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: <IntlMessages id="ui-general-updated-at" />,
    dataIndex: 'updated_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-released-at" />,
    dataIndex: 'released_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...popupsConfig?.release_range ? (
    [ {
      title: <IntlMessages id="ui-general-released-to" />,
      dataIndex: 'released_to',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.12,
      render: (value, item) => {
        if (!item.released_to) {
          return item.released_at ? <PiInfinity /> : EMPTY_VALUE_PLACEHOLDER;
        }
        return dayjs(item.released_to).utc().format(DATE_TIME_FORMAT);
      },
    } ]
  ) : [],
  ...(HasRights([
    Permissions.OTHER.POPUPS.FIND,
    Permissions.OTHER.POPUPS.EDIT,
    Permissions.OTHER.POPUPS.DELETE,
    Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW,
  ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.13,
      render: (item) => {
        const dictionaryId = getDictionaryIdFromDynamicData(item?.dynamic_data?.data);
        const canEditPastItem = popupsConfig?.publish?.allow_update_past ?? true;
        const canEditItem = canEditPastItem ? true : dayjs().isSameOrBefore(dayjs(item?.released_at).subtract(-1, 'days'), 'day');

        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.FIND ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Eye
                    onClick={() => {
                      handleViewDetails(item.id);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.EDIT ]}>
              {canEditItem && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                  <div className="hp-text-right">
                    <Link to={urlPagePopupEdit({ popupId: item.id })}>
                      <PiPencil
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                      />
                    </Link>
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.DELETE ]}>
              {!item.is_published ? (
                <Popconfirm
                  title={<IntlMessages id="ui-general-delete-confirm-message" />}
                  placement="top"
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="features-delete-notification" />}>
                  <div className="hp-text-right">
                    <Delete
                      disabled
                      size={20}
                      className="hp-opacity-4 hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
              {dictionaryId && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-dictionary" />}>
                  <div className="hp-text-right">
                    <IoEarth
                      size={20}
                      onClick={() => {
                        handlePreviewDictionary(dictionaryId);
                      }}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
          </Row>
        );
      },
    } ] : []
  ),
];
