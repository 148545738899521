import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import { hasRights, Permissions } from '../../../../const/permissions';
import { DATE_FORMAT } from '../../../../const/system';
import urlPagePopups from '../../../../urls/urlPagePopups';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import applyFilter from '../../../../lib/applyFilter';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import handleResponse from '../../../../lib/handleResponse';
import getPriorityUpdateButtonTooltipMessage from '../../../../lib/getPriorityUpdateButtonTooltipMessage';
import Spinner from '../../../../components/Spinner';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import PrioritiesTitle from '../../../../components/PrioritiesTitle';
import PriorityFilter from '../../../../components/PriorityFilter';
import PopupPriorityList from '../../components/PopupPriorityList';
import { useGetPopupsPriorityQuery, useUpdatePopupsPriorityMutation } from '../../api/popupsApiSlice';



const PagePopupsPriority = () => {
  const intl = useIntl();
  const [ popupsOrder, setPopupsOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFilterFields = [ 'date' ];
  const isMobile = useCheckMobileScreen();

  const { data: popups = { data: [], pagination: {} }, isFetching } = useGetPopupsPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}` : '',
  });

  const [ updatePopupsPriority, { isLoading: isUpdatingPopupsPriority } ] = useUpdatePopupsPriorityMutation();

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;
  const hasNoOrderChanges = !popupsOrder.length;


  const onPopupsOrderChange = (order) => {
    setPopupsOrder(order);
  };

  const submitPopupsPriority = () => {
    const order = popupsOrder.map((item) => {
      const position = Number(item) - 1;

      return popups.data[position]?.id;
    });

    updatePopupsPriority({ order, date })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => setPopupsOrder([]),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePopups()}>
              <IntlMessages id='popups-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-priority' />}
        />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges}
                hasPermission={hasRights([ Permissions.OTHER.POPUPS.PRIORITY.UPDATE ])}
                title={intl.formatMessage({ id: 'popups-priority-title' })}
                onAction={submitPopupsPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges)}
              />
            )}
          >
            <Spinner spinning={isFetching || isUpdatingPopupsPriority}>
              <PopupPriorityList
                data={popups.data}
                isLoading={isFetching || isUpdatingPopupsPriority}
                onOrderChange={onPopupsOrderChange}
                selectedDate={date}
              />
            </Spinner>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'popups-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              isSubmitting={isFetching || isUpdatingPopupsPriority}
              initialValues={searchQueryParams.get('search') ? { date } : {}}
              onCancel={() => {}}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  callbacks: [ () => setPopupsOrder([]) ],
                });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PagePopupsPriority;
